@import "variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.table > thead > tr{
  border-top: hidden;
  color: #72777a;
}

.h-100vh {
  min-height: 100vh;
}


.overlay {
  background-color: rgba(239, 239, 239, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;

}
