$primary: #5d2888;



$gray-200: lighten($primary, 70%);
$gray-300: lighten($primary, 60%);
$gray-400: lighten($primary, 50%);
$gray-500: lighten($primary, 40%);
//$gray-600: lighten($primary, 30%);
$gray-700: lighten($primary, 20%);
$gray-800: lighten($primary, 10%);
$gray-900: $primary;



$headings-color: $primary;

// Tables
$table-dark-bg: $primary;

$jumbotron-padding: 2rem;
$jumbotron-color: white;
$jumbotron-bg: white;


$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$rounded-pill:                50rem !default;
