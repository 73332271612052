.table .thead-dark {
  th {
    color: #fff;
    background-color: #5d2888;
    border-color: #7131a6;
    vertical-align: bottom;
    border-bottom: 2px solid #f2e9f9;
  }
}

.table {
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f2e9f9;
  }

  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f2e9f9;
  }
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a {
  color: #5d2888;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
}

.list-group-item {
  padding: 0.75rem 1.25rem;
}

.table > :not(:first-child) {
  border-top: none;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.factor_sorting {
  cursor: pointer;
  vertical-align: middle;

  div {
    display: inline-block;
    margin-left: 10px;
    width: 10px;
    svg {
      display: none;
    }
    .fa-sort-up {
      top: 5px;
      position: relative;
    }
  }

  &:hover {
    svg {
      display: inline-block;
    }
  }
}

.factor_sorting.sorting_visible {
  svg {
    display: inline-block;
  }
}


.fixScoreCard {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  right: 10px;
  padding: 10px 20px;
  background: #f9fafb;
  color: #41464b;
  border-radius: 2px;
  box-shadow: 2px 0 6px 0 rgba(35, 34, 34, 0.5);
}

.rc-slider-mark {
  font-size: 14px!important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}
.rc-slider-dot {
  height: 16px !important;
  width: 16px !important;
}
.rc-slider-mark-text {
  max-width: 96px!important;
  @media (max-width: 600px) {
    font-size: 12px!important;
    max-width: 70px!important;
  }
}

.report-card, .governance-report-card {
  width: 300px;
  border: 1px solid;
  border-radius: 3px;
  color: #202020;
  flex: auto;
  margin: 10px;
  .colored-header {
    border-bottom: 1px solid black;
    padding: 10px;
    text-align: right;
    font-size: 12px;
    font-weight: 800;
  }
  .content {
    padding: 10px;
    .label-row {
      margin-bottom: 20px;
    }
    .value-row {
      text-align: center;
      margin-bottom: 20px;
    }
    .explanation {
      font-size: 12px;
    }
    .label {
      font-size: 17px;
      font-weight: 800;
    }
    .value {
      font-size: 32px;
      font-weight: 800;
    }
    .unit {
      font-size: 12px;
      position: relative;
      top: -14px;
      left: 5px;
    }
  }
}
.governance-report-card {
  min-width: 70%;
  max-width: 600px;
  .colored-header {
    display: flex;
    justify-content: space-between;
    div{
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    div:first-child {
      justify-content: flex-start;
      width: 70%;
    }
  }
  .out-of {
    margin: 0 15px;
  }
}

.reportChart {
  .recharts-label {
    fill: white!important;
    tspan {
      fill: white!important;
      stroke: white;
    }
  }
}

.dynamicDescription {
  br {
    margin:2.5em 0;/* FF for instance */
    line-height:5em;/* chrome for instance */
  }
  text-align: justify;
}

@media print {
  .holdTogetherOnPrint {
    break-inside: avoid;
  }
  .modal-backdrop.show {
    opacity: 1;
    background: white;
  }
}

.nav-link {
  cursor: pointer;
}
